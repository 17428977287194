* {
	box-sizing: border-box;
}

:root {
	color: black;
	font-family: Tahoma, Verdana, Arial, sans-serif;
	font-size: 16px;
}

body {
	margin: 0;
}

#gameContainer {
	position: relative;
	margin: 0 auto;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#background {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: -10000;
	background: linear-gradient(0deg, rgba(246, 226, 175, 1) 0%, rgba(185, 226, 229, 1) 35%, rgba(185, 229, 217, 1) 100%);
}

#waves {
	position: absolute;
	left: 20px;
	top: 20px;
	font-size: 40px;
	color: #FFFC67;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	font-variant-numeric: tabular-nums;
	font-family: Verdana;
}

#timer {
	z-index: 9002;
	position: absolute;
	width: 20%;
	text-align: center;
	font-size: 70px;
	margin-top: 10;
	margin-left: 40%;
	color: white;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	padding: 0;
	font-variant-numeric: tabular-nums;
	font-family: Verdana;
	transition: all 0.5s;
}

.timerAnimated10 {
	animation-name: timerAnimation10;
	animation-duration: 1s;
	animation-direction: normal;
	animation-timing-function: cubic-bezier();
	animation-iteration-count: infinite;
}

@keyframes timerAnimation10 {
	0% {
		transform: scale(1);
	}

	25% {
		transform: scale(2.5) rotate(36deg);
	}

	50% {
		transform: scale(1);
	}
}



.timerAnimated {
	animation-name: timerAnimation;
	animation-duration: 1s;
	animation-direction: normal;
	animation-timing-function: cubic-bezier();
	animation-iteration-count: infinite;
}

@keyframes timerAnimation {
	0% {
		transform: scale(1);
	}

	25% {
		transform: scale(1.5);
	}

	50% {
		transform: scale(1);
	}
}

#toolbar {
	position: absolute;
	left: 20px;
	top: 80px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

#toolbarButtonContainer {
	position: absolute;
	top: 0px;
	left: 0;
	right: 0;
	height: 100px;
	width: 1100px;
	margin-left: auto;
	margin-right: auto;
	float: left;
}

#toolbarButtonContainer>.hidden {
	visibility: hidden;
}

.play-button {
	cursor: pointer;
	user-select: none;
	opacity: 1;
	filter: saturate(50%) brightness(100%);
}

.play-button:hover {
	transform: scale(1.2);
}

.toolbar-button {
	flex: 1;
	height: 50px;
	margin: 10px;
	box-sizing: border-box;
	cursor: pointer;
	user-select: none;
	object-fit: contain;
	opacity: 0.5;
	filter: saturate(50%) brightness(100%);
}

.toolbar-button.is-selected {
	opacity: 1;
	filter: brightness(100%);
}

.toolbar-button:hover {
	transform: scale(1.2);
}

.tile {
	position: absolute;
	width: 100px;
	height: 100px;
	background-size: 100%;
	z-index: 0;
	text-align: center;
	line-height: 90px;

}

.cloud {
	position: absolute;
	width: 100px;
	height: 100px;
	background-size: 100%;
	z-index: 1;
	line-height: 90px;
	opacity: '60%';
}

.tile-axe-hover {
	animation-name: axeTileHover;
	animation-duration: 0.1s;
	animation-direction: normal;
	animation-timing-function: cubic-bezier();
	animation-iteration-count: 1;
}

@keyframes axeTileHover {
	0% {
		transform: scale(1) translate(0, 0);
	}

	70% {
		transform: translate(0, 1px) rotate(5deg);
	}

	100% {
		transform: scale(1) translate(0, 0) rotate(0deg);
	}
}

.tile-axe-hit {
	animation-name: axeTileHit;
	animation-duration: 0.1s !important;
	animation-direction: normal !important;
	animation-timing-function: cubic-bezier() !important;
	animation-iteration-count: 15 !important;
}

@keyframes axeTileHit {
	0% {
		transform: scale(1) translate(0, 0);
	}

	70% {
		transform: scale(1.2) translate(0, -2px) rotate(10deg);
	}

	100% {
		transform: scale(1) translate(0, 0) rotate(0deg);
	}
}



.highlight {
	filter: hue-rotate(90deg) !important;
}

.circle {
	position: absolute;
	width: 150px;
	opacity: 70%;
}

.goal {
	position: absolute;
	width: 100px;
	opacity: 0.1;
}

.spawn {
	position: absolute;
	width: 100px;
	opacity: 0.1;
	z-index: 0.1;
}

.animal {
	position: absolute;
	width: 70px;
	height: 70px;
	background-size: 100%;
	opacity: 0.1;
	z-index: 1;
}

.shadow {
	position: absolute;
	width: 35px;
	height: 7px;
	background-color: rgba(0, 0, 0, 1);
	filter: blur(5px);
}

.flag {
	position: absolute;
	width: 30px;
}

.east>.flag {
	left: 0;
	transform: scaleX(-1);
}

.west>.flag {
	right: 0;
}

.popup {
	position: absolute;
	width: 300px;
	height: 100px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;

	text-align: center;
	/* background-color: burlywood; */
	color: black;
	font-size: 20px;
}

.game-over-popup-element {
	display: flex;
	vertical-align: middle;
	align-items: center;
	justify-content: center;
}

.game-over-popup-element div {
	font-size: 30px;
	color: white;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
}

.particle {
	position: absolute;
	width: 10px;
	height: 10px;
}

.sign {
	position: absolute;
	width: 50px;
	height: 50px;
	background-size: 100%;
	animation-name: signIntro;
	animation-duration: 0.3s;
	animation-direction: normal;
	animation-timing-function: cubic-bezier();
	animation-iteration-count: 1;
}

.animatedSign {
	animation-name: signChange;
	animation-duration: 0.3s;
	animation-direction: normal;
	animation-timing-function: cubic-bezier();
	animation-iteration-count: 1;
}

@keyframes signChange {
	from {
		transform: scale(1);
	}

	30% {
		transform: scale(0.7);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes signIntro {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	50% {
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}


.animatedHint {
	animation-name: hintIntro;
	animation-duration: 0.3s;
	animation-direction: normal;
	animation-timing-function: cubic-bezier();
	animation-iteration-count: 1;
}

.gameOverBackground {
	filter: blur(1px);
}

.pausedBackground {
	filter: grayscale();
}

@keyframes hintIntro {
	from {
		transform: scale(0);
	}

	30% {
		transform: scale(1.2);
	}

	50% {
		transform: scale(0.9);
	}

	100% {
		transform: scale(1);
	}
}

#gameContainerBackground {
	position: absolute;
	top: 72%;
	width: 50%;
	height: 25%;
	transform: skew(0deg, 35deg);
	background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(159, 100, 50, 1) 100%);
}

#gameContainerBackground2 {
	position: absolute;
	top: 72%;
	width: 50%;
	height: 25%;
	right: 0;
	transform: skew(0deg, -35deg);
	background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(159, 100, 50, 1) 100%);
}

.player-axe-cursor {
	cursor: url('./assets/tools/axe.png?width=50'), auto;
}

.axeTool {
	pointer-events: none;
	cursor: default;
	height: 75px;
	position: absolute;
	padding-left: 20px;
	opacity: 1;

	animation-name: axeIntro;
	animation-duration: 1.3s;
	animation-direction: normal;
	animation-timing-function: cubic-bezier();
	animation-iteration-count: 1;
	z-index: 1000;
}

.axeTool:hover {
	transform: scale(1);

}


@keyframes axeIntro {
	0% {
		transform: scale(3) translate(-166px, 166px);
	}

	25% {
		transform: scale(4) translate(-125px, 125px) rotate(360deg);
	}

	50% {
		transform: scale(3) translate(-166px, 166px), rotate(0deg);
	}

	70% {
		transform: scale(3) translate(-166px, 166px), rotate(0deg);
	}

	100% {
		transform: scale(1) translate(0, 0) rotate(0deg);
	}
}

.titleContainer {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	margin-top: 350px;
	color: white;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: top;
}

.titleContainer * {
	pointer-events: none;
	user-select: none;
}

h1 {
	font-size: 80px;
}

h2 {
	font-size: 30px;
}

.titleContainerBackground {
	filter: blur(5px) grayscale(0.4);
	animation-name: backgroundFadein;
	animation-duration: 2s;
	animation-direction: normal;
	animation-timing-function: quadratic;
	animation-iteration-count: 1;
}

@keyframes backgroundFadein {
	0% {
		transform: translate(0%, -300%);
		opacity: 0%;
	}

	100% {
		transform: translate(0%, 0%);
		opacity: 100%;
	}

}