* {
  box-sizing: border-box;
}

:root {
  color: #000;
  font-family: Tahoma, Verdana, Arial, sans-serif;
  font-size: 16px;
}

body {
  margin: 0;
}

#gameContainer {
  -webkit-user-select: none;
  user-select: none;
  margin: 0 auto;
  position: relative;
}

#background {
  height: 100%;
  width: 100%;
  z-index: -10000;
  background: linear-gradient(0deg, #f6e2af 0%, #b9e2e5 35%, #b9e5d9 100%);
  position: fixed;
  top: 0;
  left: 0;
}

#waves {
  color: #fffc67;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  font-variant-numeric: tabular-nums;
  font-family: Verdana;
  font-size: 40px;
  position: absolute;
  top: 20px;
  left: 20px;
}

#timer {
  z-index: 9002;
  width: 20%;
  text-align: center;
  color: #fff;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  font-variant-numeric: tabular-nums;
  margin-top: 10px;
  margin-left: 40%;
  padding: 0;
  font-family: Verdana;
  font-size: 70px;
  transition: all .5s;
  position: absolute;
}

.timerAnimated10 {
  animation-name: timerAnimation10;
  animation-duration: 1s;
  animation-direction: normal;
  animation-timing-function: cubic-bezier();
  animation-iteration-count: infinite;
}

@keyframes timerAnimation10 {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(2.5)rotate(36deg);
  }

  50% {
    transform: scale(1);
  }
}

.timerAnimated {
  animation-name: timerAnimation;
  animation-duration: 1s;
  animation-direction: normal;
  animation-timing-function: cubic-bezier();
  animation-iteration-count: infinite;
}

@keyframes timerAnimation {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.5);
  }

  50% {
    transform: scale(1);
  }
}

#toolbar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 80px;
  left: 20px;
}

#toolbarButtonContainer {
  height: 100px;
  width: 1100px;
  float: left;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#toolbarButtonContainer > .hidden {
  visibility: hidden;
}

.play-button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  opacity: 1;
  filter: saturate(50%) brightness();
}

.play-button:hover {
  transform: scale(1.2);
}

.toolbar-button {
  height: 50px;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  object-fit: contain;
  opacity: .5;
  filter: saturate(50%) brightness();
  flex: 1;
  margin: 10px;
}

.toolbar-button.is-selected {
  opacity: 1;
  filter: brightness();
}

.toolbar-button:hover {
  transform: scale(1.2);
}

.tile {
  width: 100px;
  height: 100px;
  z-index: 0;
  text-align: center;
  background-size: 100%;
  line-height: 90px;
  position: absolute;
}

.cloud {
  width: 100px;
  height: 100px;
  z-index: 1;
  opacity: "60%";
  background-size: 100%;
  line-height: 90px;
  position: absolute;
}

.tile-axe-hover {
  animation-name: axeTileHover;
  animation-duration: .1s;
  animation-direction: normal;
  animation-timing-function: cubic-bezier();
  animation-iteration-count: 1;
}

@keyframes axeTileHover {
  0% {
    transform: scale(1)translate(0);
  }

  70% {
    transform: translate(0, 1px)rotate(5deg);
  }

  100% {
    transform: scale(1)translate(0)rotate(0);
  }
}

.tile-axe-hit {
  animation-name: axeTileHit;
  animation-duration: .1s !important;
  animation-direction: normal !important;
  animation-timing-function: cubic-bezier() !important;
  animation-iteration-count: 15 !important;
}

@keyframes axeTileHit {
  0% {
    transform: scale(1)translate(0);
  }

  70% {
    transform: scale(1.2)translate(0, -2px)rotate(10deg);
  }

  100% {
    transform: scale(1)translate(0)rotate(0);
  }
}

.highlight {
  filter: hue-rotate(90deg) !important;
}

.circle {
  width: 150px;
  opacity: .7;
  position: absolute;
}

.goal {
  width: 100px;
  opacity: .1;
  position: absolute;
}

.spawn {
  width: 100px;
  opacity: .1;
  z-index: .1;
  position: absolute;
}

.animal {
  width: 70px;
  height: 70px;
  opacity: .1;
  z-index: 1;
  background-size: 100%;
  position: absolute;
}

.shadow {
  width: 35px;
  height: 7px;
  filter: blur(5px);
  background-color: #000;
  position: absolute;
}

.flag {
  width: 30px;
  position: absolute;
}

.east > .flag {
  left: 0;
  transform: scaleX(-1);
}

.west > .flag {
  right: 0;
}

.popup {
  width: 300px;
  height: 100px;
  text-align: center;
  color: #000;
  flex-direction: column;
  place-content: center;
  margin: 0 auto;
  font-size: 20px;
  display: flex;
  position: absolute;
}

.game-over-popup-element {
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: flex;
}

.game-over-popup-element div {
  color: #fff;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  flex-direction: column;
  place-content: center;
  font-size: 30px;
  display: flex;
}

.particle {
  width: 10px;
  height: 10px;
  position: absolute;
}

.sign {
  width: 50px;
  height: 50px;
  animation-name: signIntro;
  animation-duration: .3s;
  animation-direction: normal;
  animation-timing-function: cubic-bezier();
  background-size: 100%;
  animation-iteration-count: 1;
  position: absolute;
}

.animatedSign {
  animation-name: signChange;
  animation-duration: .3s;
  animation-direction: normal;
  animation-timing-function: cubic-bezier();
  animation-iteration-count: 1;
}

@keyframes signChange {
  from {
    transform: scale(1);
  }

  30% {
    transform: scale(.7);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes signIntro {
  from {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, -20px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.animatedHint {
  animation-name: hintIntro;
  animation-duration: .3s;
  animation-direction: normal;
  animation-timing-function: cubic-bezier();
  animation-iteration-count: 1;
}

.gameOverBackground {
  filter: blur(1px);
}

.pausedBackground {
  filter: grayscale();
}

@keyframes hintIntro {
  from {
    transform: scale(0);
  }

  30% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

#gameContainerBackground {
  width: 50%;
  height: 25%;
  background: linear-gradient(0deg, #000 0%, #9f6432 100%);
  position: absolute;
  top: 72%;
  transform: skew(0deg, 35deg);
}

#gameContainerBackground2 {
  width: 50%;
  height: 25%;
  background: linear-gradient(0deg, #000 0%, #9f6432 100%);
  position: absolute;
  top: 72%;
  right: 0;
  transform: skew(0deg, -35deg);
}

.player-axe-cursor {
  cursor: url("axe.5a99b615.png"), auto;
}

.axeTool {
  pointer-events: none;
  cursor: default;
  height: 75px;
  opacity: 1;
  animation-name: axeIntro;
  animation-duration: 1.3s;
  animation-direction: normal;
  animation-timing-function: cubic-bezier();
  z-index: 1000;
  padding-left: 20px;
  animation-iteration-count: 1;
  position: absolute;
}

.axeTool:hover {
  transform: scale(1);
}

@keyframes axeIntro {
  0% {
    transform: scale(3)translate(-166px, 166px);
  }

  25% {
    transform: scale(4)translate(-125px, 125px)rotate(360deg);
  }

  50% {
    transform: scale(3) translate(-166px, 166px), rotate(0deg);
  }

  70% {
    transform: scale(3) translate(-166px, 166px), rotate(0deg);
  }

  100% {
    transform: scale(1)translate(0)rotate(0);
  }
}

.titleContainer {
  height: 100%;
  width: 100%;
  color: #fff;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  margin-top: 350px;
  display: inline-flex;
  position: fixed;
  top: 0;
  left: 0;
}

.titleContainer * {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

h1 {
  font-size: 80px;
}

h2 {
  font-size: 30px;
}

.titleContainerBackground {
  filter: blur(5px) grayscale(.4);
  animation-name: backgroundFadein;
  animation-duration: 2s;
  animation-direction: normal;
  animation-timing-function: quadratic;
  animation-iteration-count: 1;
}

@keyframes backgroundFadein {
  0% {
    opacity: 0;
    transform: translate(0%, -300%);
  }

  100% {
    opacity: 1;
    transform: translate(0%);
  }
}

/*# sourceMappingURL=index.aa009c67.css.map */
